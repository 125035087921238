import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import DataTable from 'components/DataTable';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FolderFilled } from '@ant-design/icons';
import { ButtonDataTableTypeItems } from 'constant/Types/DataTableConfigType';
import { useLocation, useNavigate } from 'react-router';
import ModalFilter from './ModalFilter';
import Toast from 'components/Toast';
import { transformError } from 'utils/ErrorTransformer';
import { hasPermission } from 'utils/Permission';
import moment from 'moment-timezone';
import {
  useGetPermitMonitoringMutation,
  useDetailPermitMonitoringMutation
} from 'api/PermitMonitoring';
import dayjs from 'dayjs';
import ModalForm from './ModalForm';
import { useSearchParams } from 'react-router-dom';
import CardList from 'components/CardList';

const PermitMonitoring = () => {
  const [permitMonitoringList, {
    error: errorPermitMonitoringList,
    isError: isErrorPermitMonitoringList,
    isLoading
  }] = useGetPermitMonitoringMutation();

  const [permitMonitoringDetail, {
    error: errorDetail,
    isError: isErrorDetail,
    isLoading: isLoadingDetail
  }] = useDetailPermitMonitoringMutation();

  interface listData {
    total: any,
    permitMonitoring: any
  };

  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const navigate = useNavigate();
  const [data, setData] = useState<listData>({
    total: 0,
    permitMonitoring: []
  });
  const [dataMobile, setDataMobile] = useState<listData>({
    total: 0,
    permitMonitoring: []
  });
  const [payload, setPayload] = useState({
    lang: getLanguage(),
    page: 1,
    pageSize: 50,
    search: '',
    sort: 'expired_date',
    processed_by: [],
    status: ['Active'],
    permit_location_id: [],
    expired_date: ''
  });
  const [payloadMobile, setPayloadMobile] = useState({
    lang: getLanguage(),
    page: 1,
    pageSize: 50,
    search: '',
    sort: 'expired_date',
    processed_by: [],
    status: ['Active'],
    permit_location_id: [],
    expired_date: ''
  });
  const [filter, setFilter] = useState({
    processed_by: [],
    status: ['Active'],
    permit_location_id: [],
    expired_date_from: '',
    expired_date_to: ''
  });
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalFormOpen, setModalFormOpen] = useState(false);
  const [canView, setCanView] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [detail, setDetail] = useState(null);
  const [onSearchMobile, setOnSearchMobile] = useState(false);
  const [onFilterMobile, setOnFilterMobile] = useState(false);

  const applyFilter = () => {
    setIsLoadingFilter(true);
    setPayload({
      ...payload,
      page: 1,
      status: filter?.status,
      permit_location_id: filter?.permit_location_id || [],
      expired_date: filter.expired_date_from && filter.expired_date_to ?
        `${dayjs(filter?.expired_date_from).format('YYYY-MM-DD')};${dayjs(filter?.expired_date_to).format('YYYY-MM-DD')}` :
        '',
      processed_by: filter?.processed_by
    });
    setPayloadMobile({
      ...payloadMobile,
      page: 1,
      status: filter?.status,
      permit_location_id: filter?.permit_location_id || [],
      expired_date: filter.expired_date_from && filter.expired_date_to ?
        `${dayjs(filter?.expired_date_from).format('YYYY-MM-DD')};${dayjs(filter?.expired_date_to).format('YYYY-MM-DD')}` :
        '',
      processed_by: filter?.processed_by
    });
  };

  const resetFilter = () => {
    setIsLoadingFilter(true);
    setFilter({
      processed_by: [],
      status: ['Active'],
      permit_location_id: [],
      expired_date_from: '',
      expired_date_to: ''
    });
    setPayload({
      ...payload,
      processed_by: [],
      status: ['Active'],
      permit_location_id: [],
      expired_date: ''
    });
    setPayloadMobile({
      ...payloadMobile,
      processed_by: [],
      status: ['Active'],
      permit_location_id: [],
      expired_date: ''
    });
  };

  const fetchDetail = async (id: any) => {
    const dataDetail = await permitMonitoringDetail(id).unwrap();

    setDetail(dataDetail);
  };

  const fetchList = async () => {
    let convertPayload: any = payload;
    let newPayload: any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]) {
        newPayload[key] = convertPayload[key];
      }
    }

    let listPermitMonitoring = await permitMonitoringList(newPayload).unwrap();

    listPermitMonitoring = {
      ...listPermitMonitoring,
      permitMonitoring: listPermitMonitoring?.rows.map((el: any, idx: any) => ({ ...el, key: idx }))
    };

    setData(listPermitMonitoring);
    setIsLoadingFilter(false);
  };

  const fetchListMobile = async () => {
    let convertPayload: any = payloadMobile;
    let newPayload: any = {};
    for (const key in convertPayload) {
      if (Array.isArray(convertPayload[key])) {
        if (convertPayload[key].length) {
          newPayload[key] = convertPayload[key];
        }
      } else if (convertPayload[key]) {
        newPayload[key] = convertPayload[key];
      }
    }

    let listPermitMonitoring = await permitMonitoringList(newPayload).unwrap();

    const newPermitMonitoring = listPermitMonitoring?.rows.map((el: any, idx: any) => ({ ...el, key: idx }));
    const concatNewPermitMonitoring = [...dataMobile.permitMonitoring, ...newPermitMonitoring];

    listPermitMonitoring = {
      ...listPermitMonitoring,
      permitMonitoring: (!onSearchMobile && !onFilterMobile) ? concatNewPermitMonitoring : newPermitMonitoring
    };

    setOnSearchMobile(false);
    setOnFilterMobile(false);
    setDataMobile(listPermitMonitoring);
    setIsLoadingFilter(false);
  };

  useEffect(() => {
    const paths = pathname.split('/');
    if (!hasPermission(paths[1]).can_view) {
      navigate('/403');
    }
    setCanView(hasPermission(paths[1]).can_view);
    setCanCreate(hasPermission(paths[1]).can_create);
    setCanUpdate(hasPermission(paths[1]).can_update);

    if (id) {
      fetchDetail(id);
      if (hasPermission(paths[1]).can_update) {
        setTimeout(() => {
          setIsEdit(true);
          setIsView(false);
          setModalFormOpen(true);
        }, 500);
      } else {
        setTimeout(() => {
          setIsEdit(false);
          setIsView(true);
          setModalFormOpen(true);
        }, 500);
      }
    }
  }, []);

  useEffect(() => {
    setIsLoadingFilter(true);
    fetchList();;
  }, [payload]);

  useEffect(() => {
    fetchListMobile();
  }, [payloadMobile]);

  useEffect(() => {
    if (isErrorPermitMonitoringList) {
      setIsLoadingFilter(false);
      const errorMsg: any = errorPermitMonitoringList;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [errorPermitMonitoringList, isErrorPermitMonitoringList]);

  useEffect(() => {
    if (isErrorDetail) {
      const errorMsg: any = errorDetail;

      toast.error(<Toast message={t('general.error_text')} detailedMessage={`${transformError(errorMsg?.data).message}`} />);
      return;
    }
  }, [isErrorDetail]);

  useEffect(() => {
    setPayload({
      ...payload,
      lang: getLanguage()
    });

    setDataMobile({
      total: 0,
      permitMonitoring: []
    });
    setPayloadMobile({
      ...payloadMobile,
      lang: getLanguage()
    });
  }, [getLanguage()]);

  const buttonConfig: ButtonDataTableTypeItems = [
    {
      name: t('general.add_permit_monitoring'),
      link: '',
      className: 'bg-[#55A853] text-white font-bold',
      onClick: () => {
        setIsEdit(false);
        setIsView(false);
        setDetail(null);
        setModalFormOpen(true);
      }
    }
  ];

  const buttonConfigMobile: ButtonDataTableTypeItems = [
    {
      name: t('general.add_permit_monitoring'),
      link: '',
      className: 'bg-[#55A853] text-white font-bold w-[90%]',
      onClick: () => {
        setIsEdit(false);
        setIsView(false);
        setDetail(null);
        setModalFormOpen(true);
      }
    }
  ];

  const columns: ColumnsType = [
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (data, record) => (
        <div>
          {
            canView &&
            <Button
              type='text'
              className='shadow-md shadow-gray-400 rounded-sm mr-3 p-0'
              onClick={async () => {
                if (canUpdate) {
                  fetchDetail(data.id);
                  setTimeout(() => {
                    setIsEdit(true);
                    setIsView(false);
                    setModalFormOpen(true);
                  }, 500);
                } else {
                  fetchDetail(data.id);
                  setTimeout(() => {
                    setIsEdit(false);
                    setIsView(true);
                    setModalFormOpen(true);
                  }, 500);
                }
              }}
            >
              <FolderFilled style={{ fontSize: '20px' }} className='text-[#55A853] p-1' />
            </Button>
          }
        </div>
      ),
    },
    {
      title: 'No',
      dataIndex: 'number',
      width: 100,
      key: 'number',
      render: (data, record, index) => {
        return (<div>{index + 1 || '-'}</div>);
      }
    },
    {
      title: 'Permit Name',
      dataIndex: 'permit_name',
      key: 'permit_name',
      sorter: true,
      render: (data, record) => {
        return (<div>{data[getLanguage()] || '-'}</div>);
      }
    },
    {
      title: 'Permit No',
      dataIndex: 'registration_number',
      key: 'registration_number',
      sorter: true,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Remaining Days',
      dataIndex: 'remaining_days',
      key: 'remaining_days',
      sorter: true,
      render: (data, record) => {
        return <div>{data || data === 0 ? data : '-'}</div>;
      }
    },
    {
      title: 'Permit Type',
      dataIndex: 'permit_type_name',
      key: 'permit_type_name',
      sorter: true,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Permit Location',
      dataIndex: 'work_location_name',
      key: 'work_location_name',
      sorter: true,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Issued Date',
      dataIndex: 'issued_date',
      key: 'issued_date',
      sorter: true,
      render: (data, record) => {
        return (<div>{data ? moment(data).format('DD-MMM-YYYY') : '-'}</div>);
      }
    },
    {
      title: 'Released Date',
      dataIndex: 'released_date',
      key: 'released_date',
      sorter: true,
      render: (data, record) => {
        return (<div>{data ? moment(data).format('DD-MMM-YYYY') : '-'}</div>);
      }
    },
    {
      title: 'Expire Date',
      dataIndex: 'expired_date',
      key: 'expired_date',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: (data, record) => {
        return (<div>{data ? moment(data).format('DD-MMM-YYYY') : '-'}</div>);
      }
    },
    {
      title: 'Area (Ha)',
      dataIndex: 'area_ha',
      key: 'area_ha',
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Institution',
      dataIndex: 'permit_issuer_institution_name',
      key: 'permit_issuer_institution_name',
      sorter: true,
      render: (data, record) => {
        return (<div>{data || '-'}</div>);
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (data, record) => {
        return (
          <div
            className={
              `
                ml-3 py-1 px-2  rounded-lg border border-solid text-xs text-center uppercase
                ${data.toLowerCase() === 'archived' ? 'text-white bg-[#AAAAAA]' : ''}
                ${data.toLowerCase() === 'active' ? 'text-white bg-[#659711]' : ''}
                ${data.toLowerCase() === 'not active' ? 'text-white bg-[#6A131A]' : ''}
                ${data.toLowerCase() === 'expired' ? 'text-white bg-[#FF7527]' : ''}
              `
            }
          >
            {data.toLowerCase() === 'not active' ? 'Inactive': data}
          </div>
        );
      }
    }
  ];

  const getBackground = (days:string) => {
    switch (days) {
      case 'P1':
        return 'bg-[#FDEAE5]';
      case 'P2':
        return 'bg-[#FFF9D9]';
      case 'P3':
        return 'bg-[#F0FFE7]';
      default:
        return 'bg-white';
    }
  };

  const viewCardList = () => {
    return (
      <Spin tip="Loading" size="large" spinning={isLoading}>
        {
          dataMobile?.permitMonitoring?.map((el: any, idx: any) => (
            <div key={idx} className={`p-0 mt-1 mb-5 shadow-md rounded-xl p-4 ${getBackground(el?.days)}`} onClick={() => {
              if (canUpdate) {
                fetchDetail(el.id);
                setTimeout(() => {
                  setIsEdit(true);
                  setIsView(false);
                  setModalFormOpen(true);
                }, 500);
              } else {
                fetchDetail(el.id);
                setTimeout(() => {
                  setIsEdit(false);
                  setIsView(true);
                  setModalFormOpen(true);
                }, 500);
              }
            }}>
              <div className='flex justify-between mb-2'>
                <div className='my-auto'>
                  <div
                    className={
                      `
                        py-1 px-2  rounded-lg border border-solid text-xs text-center uppercase
                        ${el?.status?.toLowerCase() === 'archived' ? 'text-white bg-[#AAAAAA]' : ''}
                        ${el?.status?.toLowerCase() === 'active' ? 'text-white bg-[#659711]' : ''}
                        ${el?.status?.toLowerCase() === 'not active' ? 'text-white bg-[#6A131A]' : ''}
                        ${el?.status?.toLowerCase() === 'expired' ? 'text-white bg-[#FF7527]' : ''}
                      `
                    }
                  >
                    {el?.status?.toLowerCase() === 'not active' ? 'Inactive': el?.status}
                  </div>
                </div>
              </div>
              <p className='text-[12px] text-[#0A0A0A] mb-2'>{el?.work_location_name || '-'}</p>
              <p className='font-bold'>{el?.permit_name?.[getLanguage()] || '-'}</p>
              <p className='text-[12px] text-[#616161] mb-3'>{el?.registration_number || '-'}</p>
              <div className='flex justify-between'>
                <div>
                  <p className='text-[12px] text-[#616161]'>{t('permit_request.request_information.expired_date')}</p>
                  <p>{el?.expired_date ? moment(el?.expired_date).format('DD-MMM-YYYY') : '-'}</p>
                </div>
                <p className='self-end text-[#CD7B2E]'>{el?.remaining_days} {t('permit_monitoring.days_left')}</p>
              </div>
            </div>
          ))
        }
        {
          dataMobile?.permitMonitoring?.length !== dataMobile?.total ?
            (
              <Button
                type='text'
                className='bg-[#55A853] text-white px-10 w-full mt-4'
                disabled={isLoading}
                onClick={() => {
                  setPayloadMobile({ ...payloadMobile, page: payloadMobile.page + 1 });
                }}
              >
                {isLoading ? <Spin /> : t('general.load_more') || ''}
              </Button>
            ) :
            (<div></div>)
        }
      </Spin>
    );
  };

  return (
    <div className='w-full overflow-x-scroll'>
      <Spin tip="Loading" size="large" spinning={isLoadingDetail}>
        <ModalForm
          modalAction={{
            modalFormOpen: modalFormOpen,
            setModalFormOpen: setModalFormOpen
          }}
          fetchList={fetchList}
          isView={isView}
          isEdit={isEdit}
          detail={detail}
        />
        <ModalFilter
          modalAction={{
            isModalOpen: isModalOpen,
            setIsModalOpen: setIsModalOpen
          }}
          filter={{
            filter: filter,
            setFilter: setFilter
          }}
          filterAction={{
            isLoadingFilter: isLoadingFilter,
            resetFilter: resetFilter,
            applyFilter: applyFilter
          }}
        />
        <div className='md:hidden'>
          <CardList
            payloadMobile={{
              payloadMobile: payloadMobile,
              setPayloadMobile: setPayloadMobile
            }}
            onSearchMobile={{
              onSearchMobile: onSearchMobile,
              setOnSearchMobile: setOnSearchMobile
            }}
            onFilterMobile={{
              onFilterMobile: onFilterMobile,
              setOnFilterMobile: setOnFilterMobile
            }}
            modalOpen={{
              isModalOpen: isModalOpen,
              setIsModalOpen: setIsModalOpen
            }}
            viewCardList={viewCardList}
            buttonConfig={canCreate ? buttonConfigMobile : []}
          />
        </div>
        <div className='max-md:hidden'>
          <DataTable
            columns={columns}
            data={data.permitMonitoring}
            buttonConfig={canCreate ? buttonConfig : []}
            loading={isLoading}
            totalData={data.total}
            payload={{
              payload: payload,
              setPayload: setPayload
            }}
            modalOpen={{
              isModalOpen: isModalOpen,
              setIsModalOpen: setIsModalOpen
            }}
            scrollWidth={2500}
          />
        </div>
      </Spin>
    </div>
  );
};

export default PermitMonitoring;