import { ListPayloadType } from 'constant/Types/OptionType';
import { ProductDocObligationsBody } from 'constant/Types/PermitMonitoring';
import { Base } from './Base';

const PermitMonitoring = Base.injectEndpoints({
  endpoints: (build) => ({
    getPermitMonitoring: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/product-docs/views',
        method: 'GET',
        params
      })
    }),
    detailPermitMonitoring: build.mutation({
      query: (id: any) => ({
        url: `/product-docs/detail/${id}`,
        method: 'GET'
      })
    }),
    getComplianceStatus: build.mutation({
      query: () => ({
        url: '/product-docs/complience-status',
        method: 'GET'
      })
    }),
    getObligationsByProductDoc: build.mutation({
      query: (id: any) => ({
        url: `/product-doc-obligations/by-product-doc/${id}`,
        method: 'GET'
      })
    }),
    getObligationDetail: build.mutation({
      query: (id: any) => ({
        url: `/product-doc-obligation-actions/by-obligation/${id}`,
        method: 'GET'
      })
    }),
    postPermitMonitoring: build.mutation({
      query: (body: any) => ({
        url: '/product-docs',
        method: 'POST',
        body
      })
    }),
    postAttachmentActionObligation: build.mutation({
      query: (body: any) => ({
        url: '/product-doc-obligation-action-attachments',
        method: 'POST',
        body
      })
    }),
    postCompleteAction: build.mutation({
      query: (id: any) => ({
        url: `/product-doc-obligation-actions/${id}/complete`,
        method: 'POST',
      })
    }),
    postReviseAction: build.mutation({
      query: (data: {id: string, body: any}) => ({
        url: `/product-doc-obligation-actions/${data.id}/revise`,
        method: 'POST',
        body: data.body
      })
    }),
    putPermitMonitoring: build.mutation({
      query: (body: any) => ({
        url: `/product-docs/${body.id}`,
        method: 'PUT',
        body
      })
    }),
    putUpdateSortObligation: build.mutation({
      query: (body: any) => ({
        url: '/product-doc-obligations/update-sort',
        method: 'PUT',
        body
      })
    }),
    putObligation: build.mutation({
      query: (data: {id: string, body: any}) => ({
        url: `/product-doc-obligation-actions/update-crm/${data.id}`,
        method: 'PUT',
        body: data.body
      })
    }),
    statusPermitMonitoring: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/product-docs/status',
        method: 'GET',
        params
      })
    }),
    getPermitMonitoringCrm: build.mutation({
      query: (params: ListPayloadType) => ({
        url: '/product-docs/view-crm',
        method: 'GET',
        params
      })
    }),
    postProductDocObligations: build.mutation({
      query: (body: ProductDocObligationsBody) => ({
        url: '/product-doc-obligations',
        method: 'POST',
        body
      })
    }),
    postAddActionOnEditObligation: build.mutation({
      query: (body: any) => ({
        url: '/product-doc-obligation-actions/bulk-create',
        method: 'POST',
        body
      })
    }),
    delAttachmentActionObligation: build.mutation({
      query: (id: any) => ({
        url: `/product-doc-obligation-action-attachments/${id}`,
        method: 'DELETE'
      }),
    }),
    delObligationProductDoc: build.mutation({
      query: (id: any) => ({
        url: `/product-doc-obligations/${id}`,
        method: 'DELETE'
      }),
    }),
  })
});

export const { 
  useDetailPermitMonitoringMutation,
  useGetPermitMonitoringMutation,
  useGetComplianceStatusMutation,
  useGetObligationsByProductDocMutation,
  useGetObligationDetailMutation,
  usePostPermitMonitoringMutation,
  usePostAttachmentActionObligationMutation,
  usePostCompleteActionMutation,
  usePostReviseActionMutation,
  usePutPermitMonitoringMutation,
  usePutUpdateSortObligationMutation,
  usePutObligationMutation,
  useStatusPermitMonitoringMutation,
  useGetPermitMonitoringCrmMutation,
  usePostProductDocObligationsMutation,
  usePostAddActionOnEditObligationMutation,
  useDelAttachmentActionObligationMutation,
  useDelObligationProductDocMutation,
} = PermitMonitoring;