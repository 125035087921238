export const id = {
  splash: {
    message: 'Memeriksa beberapa hal, mohon tunggu...'
  },
  countdown: {
    info: 'Klik tombol login jika pengguna tidak memiliki akun Azure',
    login_btn: 'Masuk'
  },
  login: {
    welcome_text: 'Selamat datang di',
    welcome_text_two: 'Selamat datang di E-Permit License',
    username_label: 'Nama Pengguna',
    username_placeholder: 'Masukkan Nama Pengguna',
    password_label: 'Kata Sandi',
    password_placeholder: 'Masukkan Kata Sandi',
    domain_label: 'Domain',
    domain_placeholder: 'Masukkan Domain',
    login_btn: 'Masuk',
    register_btn: 'Daftar',
    remember_me_label: 'Ingat Saya',
    required_field: ' wajib diisi!',
    required_all_field: 'Nama Pengguna/Kata Sandi/Domain Diperlukan',
    btn_sign_in_azure: 'Masuk dengan Azure',
    text_login_classic: 'Klik di sini untuk masuk klasik',
    text_classic: 'Masuk Klasik',
    text_journey: 'Mulailah perjalanan Anda dengan akses abadi',
    text_loading_azure: 'Memuat Masuk melalui Azure',
    constant_err: {
      server_down: 'Server down atau Tidak ada koneksi internet. Silakan hubungi IT Helpdesk'
    }
  },
  user_organization: {
    title: 'Harap lengkapi kolom ini untuk melanjutkan',
    company_label: 'Perusahaan',
    worklocation_label: 'Tempat Kerja',
    department_label: 'Departemen',
    company_placeholder: 'Pilih perusahaan',
    worklocation_placeholder: 'Pilih tempat kerja',
    department_placeholder: 'Pilih departemen',
    success_submit_organization: 'Berhasil menyimpan data user',
    required_msg: {
      company: 'Mohon pilih perusahaan anda!',
      worklocation: 'Mohon pilih tempat kerja anda!',
      department: 'Mohon pilih departemen anda!'
    }
  },
  header: {
    profile_text: 'Profil',
    logout_text: 'Keluar'
  },
  manage_group: {
    filter_label: 'Filter',
    status_placeholder: 'Pilih status',
    cluster_label: 'Klaster',
    cluster_placeholder: 'Pilih klaster',
    cluster_type_label: 'Tipe Klaster',
    cluster_type_placeholder: 'Pilih tipe klaster',
    reset_btn: 'Hapus',
    apply_btn: 'Terapkan',
    confirm_text_one: 'Apakah Anda yakin akan ',
    confirm_text_two: ' Grup ini?',
    yes_btn: 'Ya',
    no_btn: 'Tidak',
    column: {
      action: 'Aksi',
      is_active: 'Aktif',
      group_name: 'Nama Grup',
      cluster: 'Klaster',
      cluster_type: 'Tipe Klaster'
    },
    add_group_btn: 'Tambah Grup',
    group_info_label: 'Info Grup',
    group_name_label: 'Nama Grup',
    group_name_placeholder: 'Masukkan nama grup',
    required_field: ' wajib diisi!',
    admin_view_label: 'Tampilan Admin',
    fill_form_validation_text: 'Tolong lengkapi formulir!',
    succes_add_form: 'Sukses Buat Grup!',
    succes_edit_form: 'Sukses Edit Grup!'
  },
  data_table: {
    show_text: 'Tampil',
    entries_text: 'Entri',
    of_text: 'dari',
    search_placeholder: 'Cari di sini...'
  },
  manage_user: {
    role_label: 'Role',
    role_placeholder: 'Pilih role',
    company_label: 'Perusahaan',
    company_placeholder: 'Pilih perusahaan',
    work_location_label: 'Tempat Kerja',
    work_location_placeholder: 'Pilih tempat kerja',
    btn_add_user: 'Tambah Pengguna',
    activate_confirm_text: 'Apakah kamu yakin untuk',
    success_msg_activate: 'Berhasil mengubah status user',
    success_text: 'Berhasil',
    active_text: 'Aktif',
    inactive_text: 'Non Aktif',
    user_text: 'pengguna',
    user_info_text: 'Info Pengguna',
    connected_chat_text: 'Obrolan Terhubung',
    label_display_name: 'Nama Tampilan',
    label_username: 'Nama Pengguna',
    label_role: 'Role',
    label_email: 'Surel',
    label_company: 'Perusahaan',
    label_worklocation: 'Tempat Kerja',
    label_is_active: 'Status Aktif',
    label_ms_teams: 'Microsoft Teams',
    label_whatsapp: 'Nomor Whatsapp',
    label_timezone: 'Zona waktu',
    placeholder_display_name: 'Masukkan nama tampilan',
    placeholder_username: 'Masukkan nama pengguna',
    placeholder_role: 'Pilih role',
    placeholder_email: 'Masukkan surel',
    placeholder_company: 'Pilih perusahaan',
    placeholder_worklocation: 'Pilih tempat kerja',
    placeholder_ms_teams: 'Masukkan tautan microsoft teams',
    placeholder_whatsapp: 'contoh:088858888888',
    placeholder_timezone: 'Pilih zona waktu',
    success_edit_user: 'Berhasil memperbarui data pengguna',
    required_msg: {
      company: 'Mohon pilih perusahaan anda!',
      worklocation: 'Mohon pilih tempat kerja anda!',
      display_name: 'Mohon masukkan nama tampilan anda!',
      username: 'Mohon masukkan nama pengguna anda!',
      role: 'Mohon pilih role anda!',
      email: 'Mohon masukkan surel anda!',
      ms_teams: 'Mohon masukkan tautan microsoft teams anda!',
      whatsapp: 'Mohon masukkan nomor whatsapp anda!',
    }
  },
  permit_request: {
    handle_tab_history: 'Riwayat & Percakapan akan tersedia setelah pengiriman',
    handle_tab_list_of_docs: 'Nama Izin dan Jenis Izin diperlukan. Lengkapi bidang ini untuk melanjutkan',
    handle_tab_list_of_docs_two: 'Daftar profil dokumen yang diperlukan masih belum ada, silakan hubungi administrator PLIS',
    handle_tab_product_doc: 'Beberapa data Product Doc masih kosong. Lengkapi bidang ini untuk melanjutkan',
    open_detail_permit: 'Buka Permit Detail',
    activate_confirm_text: 'Apakah Anda yakin sudah menerima dokumen izinnya',
    modal_edit_reference: {
      title: 'Edit Izin Referensi',
      fill_form_validation_text: 'Silahkan lengkapi formulirnya!',
    },
    modal_assign_to: {
      btn_cancel: 'Batalkan',
      btn_assign: 'Tugaskan',
      label_er_pic: 'ER PIC',
      placeholder_er_pic: 'PilihER PIC',
      label_comment: 'Komentar',
      placeholder_comment: 'Tambahkan Komentar',
    },
    modal_manual_reminder: {
      reminder: 'Pengingat',
      automaticaly: 'Otomatis',
      manually: 'Manual',
      date: 'Tanggal',
      date_placeholder: 'Pilih tanggal',
      time: 'Jam',
      time_placeholder: 'Pilih jam',
    },
    modal_reminder_feedback: {
      title: 'Apakah Anda ingin mendapatkan masukan dari izin sebelumnya, sebelum membuat izin baru?',
      rating_required_label: 'Silakan menilai semua pertanyaan!'
    },
    list_doc: {
      required_doc_label: 'Dokumen yang Diperlukan',
      request_attach_label: 'Lampiran Pemohon',
      request_attach_add_btn: 'Tambah Lampiran',
      attach_label: 'Lampiran',
      attach_placeholder: 'Pilih File',
      attach_two_placeholder: 'Seret & jatuhkan file di sini atau ',
      remark_label: 'Komentar',
      remark_placeholder: 'Masukkan Komentar',
      remark_for_placeholder: 'Komentar untuk ',
      attach_availability_label: 'Ketersediaan Lampiran',
      available_label: 'Tersedia',
      not_available_label: 'Tidak Tersedia',
      browser_txt: 'telusuri',
      upload_failed_type_one: 'File ',
      upload_failed_type_two: ' bukan jenis file yang valid',
      upload_failed_type_three: ' melebihi ukuran file maksimum ',
      template_label: 'Templat',
      failed: 'Gagal!',
      failed_upload: 'Gagal mengunggah',
      failed_delete_file: 'Gagal hapus file',
      uploading: 'Mengunggah...',
      not_found_text: 'Tidak Ditemukan'
    },
    request_information: {
      select_requestor_title: 'Pilih karyawan sebagai pemohon',
      general_information: 'Informasi Umum',
      name: 'Nama',
      request_number: 'Nomor Permintaan',
      location: 'Lokasi',
      superior: 'Superior',
      requestor_detail: 'Detil Pemohon',
      nik: 'NIK',
      division: 'Divisi',
      department: 'Departemen',
      position: 'Posisi',
      select_permit_request: 'Pilih Izin Permohonan',
      request_type: 'Jenis Permintaan',
      request_type_placeholder: 'Pilih jenis permintaan',
      processed_by: 'Diproses Oleh',
      processed_by_placeholder: 'Pilih diproses oleh',
      request_permit_information: 'Meminta Izin Informasi',
      activity: 'Aktivitas',
      activity_placeholder: 'Masukan aktivitas',
      permit_name: 'Nama Izin',
      permit_name_placeholder: 'Pilih nama izin',
      permit_type: 'Jenis Izin',
      permit_type_placeholder: 'Pilih jenis izin',
      permit_tier: 'Tingkat Izin',
      permit_tier_placeholder: 'Pilih tingkat izin',
      issuer_institution: 'Lembaga Penerbit',
      required_time: 'Waktu yang Diperlukan',
      required_time_placeholder: 'Pilih waktu yang diperlukan',
      permit_location: 'Izin Lokasi',
      permit_location_placeholder: 'Pilih izin lokasi',
      required_date: 'Tanggal yang Diperlukan',
      required_date_placeholder: 'Pilih tanggal yang diperlukan',
      description: 'Keterangan',
      description_placeholder: 'Masukkan deskripsi',
      location_placeholder: 'Pilih lokasi',
      er_pic_location: 'ER PIC (Lokasi)',
      er_pic_location_placeholder: 'Pilih ER PIC (Lokasi)',
      er_pic_name: 'ER PIC (Nama)',
      er_pic_name_placeholder: 'Pilih ER PIC (Nama)',
      guidance_url: 'URL Panduan',
      guidance_url_placeholder: 'Masukan url panduan',
      guidance_image: 'Gambar Panduan',
      change_requestor_confirm: 'Lokasi kerja karyawan berbeda, lanjutkan ?',
      success_message_save_draft: 'Berhasil menyimpan dokumen permit request',
      success_message_submit: 'Berhasil membuat dokumen permit request',
      success_message_resubmit: 'Berhasil kirim ulang dokumen permit request',
      reference_permit: 'Izin Referensi',
      reference_permit_placeholder: 'Masukkan izin referensi',
      reference_permit_select_placeholder: 'Pilih izin referensi',
      expired_date: 'Tanggal Kedaluwarsa',
      expired_date_placeholder: 'Pilih tanggal kedaluwarsa',
      active_date: 'Tanggal Aktif',
      active_date_placeholder: 'Pilih tanggal aktif',
      hint_active_date: 'Tanggal permit diberlakukan',
      release_date: 'Tanggal Rilis',
      release_date_placeholder: 'Pilih tanggal rilis',
      hint_release_date: 'Tanggal surat permit diterbitkan',
      extend_required: 'Diperlukan Perpanjangan',
      extend_required_placeholder: 'Pilih diperlukan perpanjangan',
      work_location: 'Tempat Kerja',
      work_location_placeholder: 'Pilih tempat kerja',
      list_docs_confirm: 'Ada beberapa daftar dokumen yang masih belum disimpan. Anda yakin ingin mengubah nama izin?',
      success_message_revise: 'Berhasil mengembalikan dokumen permit request',
      success_message_approve: 'Berhasil menyetujui dokumen permit request',
      success_message_assign_to: 'Berhasil menetapkan ER PIC ke dokumen permit request',
      success_message_pick_up: 'Berhasil mengambil dokumen permit request',
      success_message_return: 'Berhasil mengembalikan dokumen permit request',
      success_message_reject: 'Berhasil menolak dokumen permit request',
      success_message_process: 'Berhasil memproses dokumen permit request',
      success_message_resubmission: 'Berhasil mengirim ulang dokumen permit request',
      success_message_accept: 'Berhasil menerima dokumen permit request',
      hint_select_processed_by: 'Pilih \'Diproses Oleh\' terlebih dahulu',
      must_before: 'harus sebelum',
      empty_expired_date: 'Tanggal Kedaluwarsa kosong'
    },
    history_conversation: {
      title: 'Riwayat & Percakapan',
      history_title: 'Riwayat',
      conversation_title: 'Percakapan',
      input_message_placeholder: 'Ketik pesan anda',
      comment_title: 'Komentar',
      report_title: 'Laporan',
      report: {
        indicator_title: 'Indikator',
        indicator_placeholder: 'Pilih indikator',
        expected_granted_title: 'Perkiraan Terbit',
        expected_granted_placeholder: 'Pilih Perkiraan Terbit Pada',
        item_title: 'Barang',
        item_placeholder: 'Masukkan izin detail barang'
      }
    },
    product_doc: {
      document_information: 'Informasi Dokumen',
      reg_number: 'Nomor Reg',
      accept_5_files: 'Hanya 5 file yang diterima',
      error_limit_file: 'Jumlah file melebihi batas',
      attachment_url: 'Lampiran melalui tautan',
      label_attachment_url: 'Tempelkan tautan URL di sini',
      or_label: 'ATAU'
    },
    invalid_active_or_release_date: 'Tanggal Aktif atau Tanggal Rilis harus benar-benar sebelum Tanggal Kedaluwarsa',
    column: {
      date_modified: 'Tanggal Diubah',
      er_pic: 'ER PIC'
    }
  },
  permit_tracker: {
    period_date: 'Tanggal Periode',
    period_date_from_placeholder: 'Pilih Tanggal Periode dari',
    period_date_to_placeholder: 'Pilih Tanggal Periode ke',
    expired_date: 'Expired Date',
    expired_date_from_placeholder: 'Pilih tanggal kedaluwarsa dari',
    expired_date_to_placeholder: 'Pilih tanggal kedaluwarsa ke',
    date_error: 'Tanggal ke harus lebih besar dari tanggal dari!',
    to_txt: 'Ke'
  },
  permit_name: {
    processed: 'Diproses',
    processed_est_time: 'Waktu estimasi yang diproses',
    required_docs: 'Dokumen yang Diperlukan',
    permit_info: 'Info Izin',
    permit_name_placeholder: 'Masukkan nama izin',
    priority_number: 'Nomor Prioritas',
    priority_number_placeholder: 'Masukkan nomor prioritas',
    process_estimation: 'Estimasi Proses',
    process_estimation_placeholder: 'Masukkan estimasi proses',
    institution: 'Lembaga',
    institution_placeholder: 'Ketik untuk mencari lembaga',
    reminder_management: 'Manajemen Pengingat',
    reminder_before: 'Pengingat Sebelum',
    reminder_before_placeholder: 'Masukkan pengingat sebelum',
    frequency_reminder: 'Pengingat Frekuensi',
    frequency_reminder_placeholder: 'Masukkan pengingat frekuensi',
    selected_role_for_reminder: 'Role yang Dipilih untuk Pengingat',
    select_role_placeholder: 'Pilih role',
    list_of_required_document: 'Daftar Dokumen yang Diperlukan',
    add_new_doc_btn: 'Tambahkan Dokumen Baru',
    name_of_document: 'Nama Dokumen',
    name_of_document_placeholder: 'Masukkan nama dokumen',
    upload_template: 'Unggah Templat',
    save_permit_name_btn: 'Simpan Nama Izin',
    success_message_submit: 'Berhasil membuat nama izin',
    success_message_update: 'Berhasil memperbaharui nama izin',
    permit_guidance: 'Panduan Izin',
    permit_name_id: 'Nama Izin (ID)',
    permit_name_en: 'Nama Izin (EN)',
    placeholder_permit_name_id: 'Masukkan nama izin (ID)',
    placeholder_permit_name_en: 'Masukkan nama izin (EN)',
    obligation: {
      add_obligation: 'Tambahkan Kewajiban',
      edit_obligation: 'Edit Kewajiban',
      column: {
        action: 'Aksi',
        obligation_name: 'Nama Kewajiban'
      },
      delete_obligation: 'Hapus Kewajiban',
      success_delete: 'Sukses menghapus kewajiban',
      failed_delete: 'Gagal menghapus kewajiban',
      action_name: 'Nama Tindakan',
      last_update: 'Pembaruan Terakhir',
      add_action: 'Tambahkan Tindakan',
      action: 'Tindakan',
      action_placeholder: 'Masukkan tindakan',
      obligation_name: 'Nama Kewajiban',
      obligation_name_placeholder: 'Masukkan nama kewajiban',
      failed_sorting: 'Gagal memindahkan item',
      success_submit_add_obligation: 'Berhasil menambahkan kewajiban',
      failed_submit_add_obligation: 'Gagal menambahkan kewajiban',
      success_submit_update_obligation: 'Berhasil memperbarui kewajiban',
      failed_submit_update_obligation: 'Gagal memperbarui kewajiban'
    },
    cant_move_to_tab_obligation: 'Anda tidak memiliki akses ke tab kewajiban',
  },
  permit_monitoring: {
    days_left: 'Hari Tersisa',
    processed_by: 'Diproses Oleh',
    processed_by_placeholder: 'Pilih diproses oleh',
    create_permit_monitoring: 'Create Permit Monitoring',
    edit_permit_monitoring: 'Edit Permit Monitoring',
    detail_permit_monitoring: 'Detail Permit Monitoring',
    permit_no: 'Izin No.',
    permit_no_placeholder: 'Masukkan nomer izin',
    permit_name: 'Nama Izin',
    permit_name_placeholder: 'Pilih nama izin',
    permit_location: 'Lokasi Izin',
    permit_location_placeholder: 'Pilih lokasi izin',
    area: 'Daerah (HA)',
    area_placeholder: 'Masukkan daerah',
    permit_type: 'Tipe Izin',
    permit_type_placeholder: 'Pilih tipe izin',
    institution: 'Lembaga',
    institution_placeholder: 'Pilih lembaga',
    description: 'Keterangan',
    description_placeholder: 'Masukkan keterangan',
    issued_date: 'Tanggal Dikeluarkan',
    issued_date_placeholder: 'Pilih tanggal dikeluarkan',
    release_date: 'Tanggal Rilis',
    release_date_placeholder: 'Pilih tanggal rilis',
    expired_date: 'Tanggal Kedaluwarsa',
    expired_date_placeholder: 'Pilih tanggal kedaluwarsa',
    status: 'Status',
    status_placeholder: 'Pilih status',
    success_message_submit: 'Berhasil membuat pemantauan izin',
    success_message_update: 'Berhasil memperbaharui pemantauan izin',
    title_upload_docs: 'Dokumen Produk (Hanya 5 file yang diterima)',
    remaining_days: 'Hari yang tersisa',
    activity: 'Aktivitas',
    activity_placeholder: 'Masukan aktivitas',
    permit_tier: 'Tingkat Izin',
    permit_tier_placeholder: 'Pilih tingkat izin',
    permit_info: 'Info Izin',
    progress_obligation: 'Kewajiban Kemajuan',
    remaining_expired: 'Sisa Kedaluwarsa',
    compliance_status: 'Status Kepatuhan',
    compliance_status_placeholder: 'Pilih status kepatuhan',
    requestor: 'Pemohon',
    requestor_placeholder: 'Masukan pemohon',
    sector: 'Sektor',
    sector_placeholder: 'Enter sektor',
    active_date: 'Tanggal Aktif',
    active_date_placeholder: 'Pilih tanggal aktif',
    permit_status: 'Status Izin',
    permit_status_placeholder: 'Pilih status izin',
    department: 'Departemen',
    department_placeholder: 'Pilih departemen',
    expire_reference: 'Referensi Tanggal Kedaluwarsa',
    expire_reference_placeholder: 'Pilih referensi tanggal kedaluwarsa',
    permit_reference: 'Referensi Izin',
    permit_reference_placeholder: 'Masukkan referensi izin',
    remarks: 'Komentar',
    remarks_placeholder: 'Masukkan komentar',
    attachment: 'Lampiran',
    cant_move_to_tab_obligation: 'Anda tidak memiliki akses ke tab kewajiban',
    error_validate_form: 'Beberapa kolom izin diperlukan. Silakan lengkapi kolom untuk melanjutkan',
    success_message_update_permit: 'Pemantauan izin CRM berhasil diperbarui',
    error_message_update_permit: 'Pemantauan izin CRM gagal diperbarui',
    obligation: {
      obligation_action: 'Aksi Kewajiban',
      pic: 'PIC',
      pic_placeholder: 'Pilih pic',
      status: 'Status',
      status_placeholder: 'Pilih status',
      last_update: 'Pembaruan Terakhir',
      due_date: 'Tenggat Waktu',
      due_date_placeholder: 'Pilih tenggat waktu',
      progress: 'Kemajuan',
      success_edit_obligation: 'Kewajiban berhasil diedit',
      error_edit_obligation: 'Gagal mengedit kewajiban. Silakan coba lagi',
      success_submit_add_action: 'Berhasil menambahkan aksi',
      failed_submit_add_action: 'Gagal menambahkan aksi',
      complete_action: 'Selesaikan Aksi',
      success_complete_action: 'Aksi berhasil diselesaikan',
      error_complete_action: 'Gagal menyelesaikan aksi. Silakan coba lagi',
      reason: 'Alasan',
      reason_placeholder: 'Masukkan alasan',
      give_a_note: 'Berikan catatan kepada pengguna untuk memperbaiki data',
      success_revise_action: 'Aksi berhasil direvisi',
      error_revise_action: 'Gagal merevisi aksi. Silakan coba lagi',
    }
  },
  permit_type: {
    delete_permit_type: 'Hapus Jenis Izin',
    success_delete: 'Sukses menghapus jenis izin',
    failed_delete: 'Gagal menghapus jenis izin',
    permit_type_name: 'Nama Jenis Izin',
    permit_type_name_placeholder: 'Masukkan nama jenis izin',
    upload_file_sub_label: 'File maksimal 10 MB, resolusi 300 x 300',
  },
  compliance_monitoring: {
    start_date: 'Tanggal Mulai',
    end_date: 'Tanggal Berakhir',
    compliance_status: 'Status Kepatuhan',
    compliance_status_placeholder: 'Pilih status kepatuhan',
    curent_risk_assesment: 'Penilaian Risiko Saat Ini',
    curent_risk_assesment_placeholder: 'Pilih penilaian risiko saat ini',
    status: 'Status',
    status_placeholder: 'Pilih status',
    compliance_permit: 'Izin Kepatuhan',
    request_no: 'Nomor Permintaan',
    request_no_placeholder: 'Masukkan nomor permintaan',
    permit_name: 'Nama Izin',
    permit_name_placeholder: 'Masukkan nama izin',
    description: 'Keterangan',
    description_placeholder: 'Masukkan keterangan',
    er_pic: 'ER PIC',
    er_pic_placeholder: 'Pilih ER PIC',
    permit_holder: 'Pemegang Izin',
    permit_holder_placeholder: 'Masukkan pemegang izin',
    permit_location: 'Lokasi Izin',
    permit_location_placeholder: 'Masukkan lokasi izin',
    sector: 'Sektor',
    sector_placeholder: 'Masukkan sektor',
    submitted_date: 'Tanggal Dikirim',
    submitted_date_placeholder: 'Pilih tanggal dikirim',
    likelihood: 'Kemungkinan',
    likelihood_placeholder: 'Pilih kemungkinan',
    impact: 'Dampak',
    impact_placeholder: 'Pilih dampak',
    original_target: 'Sasaran Asli',
    original_target_placeholder: 'Pilih sasaran asli',
    revised_target: 'Sasaran Revisi',
    revised_target_placeholder: 'Pilih sasaran revisi',
    impact_to_the_business: 'Dampak Terhadap Bisnis',
    impact_to_the_business_placeholder: 'Masukkan dampak terhadap bisnis',
    remarks: 'Komentar',
    remarks_placeholder: 'Masukkan komentar',
    risk_assessment_status: 'Status Penilaian Risiko',
    document: 'Dokumen',
    open_link: 'Buka tautan',
    action_plan: 'Rencana aksi',
    checklist: 'Daftar periksa',
    progress: 'Kemajuan',
    add_comment: 'Tambahkan komentar',
    add_item: 'Tambahkan Item',
    add_an_item: 'Tambahkan item',
    success_message_create_permit: 'Pemantauan kepatuhan izin berhasil diedit',
    error_message_create_permit: 'Gagal mengedit pemantauan kepatuhan izin',
    fetch_action_plans_failure: 'Gagal mengambil data rencana tindakan. Silakan coba lagi.',
    fetch_more_comment_failure: 'Gagal mengambil komentar lainnya. Silakan coba lagi.',
    success: {
      action_plan_created: 'Rencana Tindakan berhasil dibuat!',
      action_plan_deleted: 'Rencana Tindakan berhasil dihapus!',
      action_plan_updated: 'Rencana Tindakan berhasil diperbarui!',
      comment_added: 'Komentar berhasil ditambahkan!',
      action_plan_checked: 'Rencana Tindakan berhasil diperiksa!',
    },
    error: {
      action_plan_create_failed: 'Gagal membuat Rencana Tindakan.',
      action_plan_delete_failed: 'Gagal menghapus Rencana Tindakan.',
      action_plan_update_failed: 'Gagal memperbarui Rencana Tindakan.',
      comment_add_failed: 'Gagal menambahkan komentar.',
      action_plan_check_failed: 'Gagal memeriksa Rencana Tindakan.',
    },
  },
  manage_cronjob: {
    cron_name: 'Nama Cron',
    integration: 'Integrasi',
    function_name: 'Nama Fungsi',
    time: 'Waktu',
    period: 'Periode',
    last_run: 'Jalan Terakhir',
    status: 'Status',
    action: 'Tindakan',
    event: 'Peristiwa',
    health_status: 'Status kesehatan',
    edit_cron_service: 'Edit Layanan Cron',
    detail_cron_service: 'Detil Layanan Cron',
    active: 'Aktif',
    inactive: 'Tidak aktif',
    plan_schedule: 'Jadwal Rencana',
    result_cron_expression: 'Ekspresi Cron Hasil',
    success: {
      update_cronjob: 'Cronjob berhasil diperbarui',
    },
    error: {
      update_cronjob: 'Gagal memperbarui cronjob',
      validation: 'Validasi gagal',
    },
  },
  general: {
    create_btn: 'Membuat',
    add_permit_name: 'Tambahkan Nama Izin',
    add_permit_monitoring: 'Tambahkan Pemantauan Izin',
    add_permit_type: 'Tambahkan Jenis Izin',
    edit_permit_type: 'Edit Jenis Izin',
    submit_btn: 'Kirim',
    update_btn: 'Memperbarui',
    resubmit_btn: 'Kirim Ulang',
    cancel_btn: 'Kembali',
    save_draft_btn: 'Simpan Draft',
    submit_edit_btn: 'Simpan',
    activate_text: 'Mengaktifkan',
    inactivate_text: 'Menonaktifkan',
    error_text: 'Gagal',
    success_text: 'Berhasil',
    select_text: 'Pilih',
    week_text: 'Minggu',
    weeks_text: 'Minggu',
    day_text: 'Hari',
    warning_text: 'Peringatan',
    required_field: ' wajib diisi!',
    loading_text: 'Memuat...',
    send_back_btn: 'Mengembalikan',
    comment_btn: 'Tambahkan komentar',
    comment_label: 'Komentar',
    approve_btn: 'Menyetujui',
    assign_to_btn: 'Ditugaskan Kepada',
    reassign_btn: 'Re-Assign',
    return_btn: 'Kembalikan',
    pick_up_btn: 'Mengambil',
    proceed_btn: 'Proses',
    reject_btn: 'Menolak',
    resubmission_btn: 'Pengiriman Ulang',
    accept_btn: 'Menerima',
    save_btn: 'Simpan',
    load_more: 'Muat Lebih',
    sort_by: 'Sortir dengan',
    no_data: 'Data Tidak Tersedia',
    label_confirm_delete: 'Apakah Anda yakin ingin menghapus dokumen ini',
    feedback_label: 'Masukan',
    feedback_rating_label: 'Peringkat & Masukan',
    label_confirm_feedback: 'Apakah Anda yakin ingin membatalkan masukan?',
    label_cancel_data: 'Apakah perubahan data akan dibatalkan',
    label_save_data: 'Apakah perubahan data akan disimpan?',
    error_form_not_complete: 'Silakan lengkapi formulirnya!',
    back: 'Kembali',
    revise: 'Revisi',
    upload_file: 'Unggah data',
    complete_btn: 'Selesai',
    send_request: 'Kirim Permintaan',
    return_label: 'Kembali',
    send: 'Kirim',
    close: 'Tutup',
    see_more: 'Lihat Selengkapnya',
    less: 'Kurang'
  },
  tab: {
    request_information: 'Informasi Permohonan',
    list_docs: 'Daftar Dokumen yang Diperlukan',
    history_conversation: 'Riwayat & Percakapan',
    product_doc: 'Produk Dok.',
    general: 'Umum',
    obligation: 'Kewajiban',
    permit: 'Izin',
    conversation: 'Percakapan',
    alignment: 'Penyelarasan',
    permit_status: 'Status Izin',
    crm: 'CRM'
  },
  notification: {
    title: 'Notifikasi',
    btn_show_all: 'Tampilkan Semua Notifikasi',
    mark_as_read: 'Tandai sebagai telah dibaca',
    no_token: 'Token pendaftaran tidak tersedia. Minta izin untuk membuatnya.',
    confirm_text: 'Apakah Anda yakin untuk menandai pesan sebagai sudah dibaca ?',
  },
  user_log: {
    last_online: 'Online Terakhir: '
  },
  dashboard: {
    year: 'Tahun',
    year_placeholder: 'Pilih tahun',
    location: 'Lokasi',
    location_placeholder: 'Pilih lokasi',
    er_pic_loc: 'LOKASI ER PIC',
    er_pic_loc_placeholder: 'Pilih lokasi ER PIC',
    permit_granted: 'Izin Diberikan',
    percent_permit_granted: '% Izin Diberikan',
    permit_by_location: 'Permintaan Izin Berdasarkan Lokasi',
    issuer_institution: 'Lembaga Penerbit',
    permit_issuer_institution: 'Permintaan Izin oleh Lembaga Penerbit',
    issuer_institution_placeholder: 'Pilih lembaga penerbit',
    by_month: 'Monitoring Perijinan',
    title_block_1: 'Total Permintaan Izin Semua Perusahaan',
    title_block_2: 'Jumlah Permohonan Izin',
    title_chart_1: 'Status Kepatuhan',
    title_chart_2: 'Kegiatan Kepatuhan',
    title_chart_3: 'Permintaan Izin Berdasarkan Sektor dan Status',
    title_period: 'Periode',
    permit_status: 'Status Izin',
    quartal: 'Kuartal',
    permit_tier: {
      title: 'Tingkat Izin',
      high: 'Tinggi',
      medium: 'Sedang',
      low: 'Rendah'
    },
    companies: {
      title: 'Perusahaan',
      btn_detail: 'Lihat Detail',
      statistic_label: 'Statistik',
      percentage_label: 'Persentase Izin yang Diberikan',
      request_submit: 'Permintaan Pengiriman',
      request_guaranted: 'Permintaan Dijamin',
      no_alignment: 'Tidak ada Alignment ke sharepoint',
      permit_status: {
        indicator_label: 'Indikator',
        indicator_placeholder: 'Pilih indikator',
        item_label: 'Barang',
        exp_granted: 'Perkiraan Terbit',
        last_update: 'Pembaruan Terakhir'
      }
    }
  },
  application_profile: {
    title_form_edit: 'Edit Profil Aplikasi',
    title_form_view: 'Detail Profil Aplikasi',
    label_key: 'Kunci',
    label_value: 'Nilai',
    label_desc: 'Deskripsi',
    placeholder_key: 'Masukkan kunci',
    placeholder_value: 'Masukkan nilai',
    placeholder_desc: 'Masukkan deskripsi',
  },
  feedback: {
    add_category: 'Tambahkan Kategori',
    add_question: 'Tambahkan Pertanyaan',
    category_placeholder: 'Ketik Kategori',
    question_placeholder: 'Ketik Pertanyaan',
    category_label: 'Kategori',
    question_label: 'Pertanyaan',
    list_of_feedback_question: 'Daftar Pertanyaan Feedback',
    success_message_submit_edit_feedback: 'Berhasil mengatur feedback',
    failed_message_submit_edit_feedback: 'Gagal mengatur feedback',
    valid_year: 'Tahun Berlaku',
    feedback_document: 'Masukan Dokumen',
    feedback_application: 'Masukan Aplikasi'
  },
  report_feedback: {
    title: 'Masukan'
  },
  report_er_performance: {
    filter_date_reset_placeholder: 'Pilih Tanggal Reset',
    reset_score: 'Atur Ulang Skor',
    er_pic_name_column: 'Nama ER PIC',
    delay_score_column: 'Skor keterlambatan',
    total_permits_processed_column: 'Total izin yang diproses',
    total_late_permits_column: 'Total izin terlambat',
    success_message_submit_reset_score: 'Berhasil mengirimkan skor reset',
    failed_message_submit_reset_score: 'Gagal mengirimkan skor reset',
    confirm_description: 'Apakah Anda yakin akan menyetel ulang ? Tindakan ini akan menghapus nilai saat ini dan memulai perhitungan dari awal',
    reset_date: 'Atur Ulang Tanggal',
    reset_date_placeholder: 'Pilih atur ulang tanggal',
    pic_work_location: 'Lokasi Kerja PIC',
    pic_work_location_placeholder: 'Pilih lokasi kerja pic',
    permit_name: 'Nama Izin',
    request: 'Permintaan',
    adjustment_delay_score: 'Penyesuaian Skor Keterlambatan',
    adjustment_date: 'Tanggal Penyesuaian',
    adjust_score: 'Penyesuaian Skor',
    score_before: 'Skor Sebelumnya',
    history_adjustment: 'Riwayat Penyesuaian',
    er_pic_name: 'Nama ER PIC',
    permit_request_no: 'Permintaan Izin No',
    adjust_delay_score: 'Penyesuaian Skor Keterlambatan',
    adjust_delay_score_placeholder: 'Masukkan penyesuaian skor keterlambatan',
    max: 'Maks',
    reason: 'Alasan',
    reason_placeholder: 'Masukkan alasan',
    evidance: 'Bukti',
    evidance_placeholder: 'Masukkan bukti',
    browse: 'Telusuri',
    days: 'hari',
    reach_max_score: 'Melebihi skor maksimum',
    confirm_submit_content: {
      first: 'Yakin ingin menyesuaikan skor penundaan dari ',
      second: 'menjadi',
      third: '? Nilainya tidak dapat dikembalikan.',
    },
    success: {
      adjustment_delay_score: 'Skor penundaan penyesuaian berhasil dibuat!',
    },
    error: {
      action_plan_create_failed: 'Gagal membuat skor penundaan penyesuaian.',
    },
  },
  clusters: {
    btn_add: 'Tambahkan Klaster',
    column: {
      action: 'Aksi',
      cluster_name: 'Nama Klaster',
      work_location: 'Lokasi Kerja'
    },
    modal_form: {
      title_add: 'Klaster Baru',
      title_edit: 'Edit Klaster',
      label_cluster_name: 'Nama Klaster',
      label_work_location: 'Lokasi Kerja',
      placeholder_cluster_name: 'Masukkan nama klaster',
      placeholder_work_location: 'Pilih lokasi kerja',
      confirm_cancel_modal_add: 'Apakah Anda yakin akan membatalkan penambahan klaster?',
      confirm_cancel_modal_edit: 'Apakah Anda yakin akan membatalkan pengeditan klaster?'
    }
  },
  permit_flow_process: {
    btn_view_guide: 'Tampilkan Pedoman'
  },
  initiate_permit_request: {
    initiate_btn: 'Memulai Permintaan Izin',
    initiate_title: 'Memulai Izin',
    permit_holder_label: 'Pemegang Izin',
    permit_holder_placeholder: 'Pilih pemegang izin',
    success_message_submit: 'Berhasil membuat permintaan izin inisiasi',
    success_message_update: 'Berhasil memperbarui permintaan izin inisiasi',
    date_label: 'Tanggal',
    initiator_label: 'Inisiator',
    modal_available_initiate: {
      title: 'Ada satu atau lebih permintaan agar Anda membuat izin'
    }
  },
  application_feedback : {
    title: 'Application Rating & Feedback',
    no_question: 'Belum ada pertanyaan'
  },
  email_notification: {
    datetime_sent: 'Tanggal Waktu Terkirim',
    subject: 'Subjek',
    email_body: 'Badan Email',
    receiver_list_email: 'Badan Email',
    range_send_date: 'Rentang Tanggal Pengiriman',
    start_date: 'Pilih tanggal mulai',
    end_date: 'Pilih tanggal akhir',
    to: 'Ke',
    cc: 'CC'
  }
};
