export const en = {
  splash: {
    message: 'Configuring some things, please wait...'
  },
  countdown: {
    info: 'Click login button if user doesn’t have Azure account',
    login_btn: 'Login'
  },
  login: {
    welcome_text: 'Welcome to',
    welcome_text_two: 'Welcome to E-Permit License',
    username_label: 'Username',
    username_placeholder: 'Input Username',
    password_label: 'Password',
    password_placeholder: 'Enter Password',
    domain_label: 'Domain',
    domain_placeholder: 'Input Domain',
    login_btn: 'Login',
    register_btn: 'Register',
    remember_me_label: 'Remember Me',
    required_field: ' is required!',
    required_all_field: 'Username/Password/Domain is Required',
    btn_sign_in_azure: 'Sign in with Azure',
    text_login_classic: 'Click here for classic login',
    text_classic: 'Classic Login',
    text_journey: 'Begin your journey with timeless access',
    text_loading_azure: 'Loading Login via Azure',
    constant_err: {
      server_down: 'Server Down or No Internet Connection. Please contact IT Helpdesk'
    }
  },
  user_organization: {
    title: 'Please complete this field to continue',
    company_label: 'Company',
    worklocation_label: 'Worklocation',
    department_label: 'Department',
    company_placeholder: 'Select company',
    worklocation_placeholder: 'Select work location',
    department_placeholder: 'Select department',
    success_submit_organization: 'Success save user data',
    required_msg: {
      company: 'Please input your company!',
      worklocation: 'Please input your worklocation!',
      department: 'Please input your department!'
    }
  },
  header: {
    profile_text: 'Profile',
    logout_text: 'Logout'
  },
  manage_group: {
    filter_label: 'Filter',
    status_placeholder: 'Select status',
    cluster_label: 'Cluster',
    cluster_placeholder: 'Select cluster',
    cluster_type_label: 'Cluster Type',
    cluster_type_placeholder: 'Select cluster type',
    reset_btn: 'Reset',
    apply_btn: 'Apply',
    confirm_text_one: 'Are you sure to ',
    confirm_text_two: ' this Group?',
    yes_btn: 'Yes',
    no_btn: 'No',
    column: {
      action: 'Action',
      is_active: 'Is Active',
      group_name: 'Group Name',
      cluster: 'Cluster',
      cluster_type: 'Cluster Type'
    },
    add_group_btn: 'Add Group',
    group_info_label: 'Group Info',
    group_name_label: 'Group Name',
    group_name_placeholder: 'Enter group name',
    required_field: ' is required!',
    admin_view_label: 'Admin View',
    fill_form_validation_text: 'Please complete the form!',
    succes_add_form: 'Success Create Group!',
    succes_edit_form: 'Success Edit Group!'
  },
  data_table: {
    show_text: 'Show',
    entries_text: 'Entries',
    of_text: 'of',
    search_placeholder: 'Search here...'
  },
  manage_user: {
    role_label: 'Role',
    role_placeholder: 'Select role',
    company_label: 'Company',
    company_placeholder: 'Select company',
    work_location_label: 'Work Location',
    work_location_placeholder: 'Select work location',
    btn_add_user: 'Add User',
    activate_confirm_text: 'Are you sure you want to',
    success_msg_activate: 'Success change user status',
    success_text: 'Success',
    active_text: 'Active',
    inactive_text: 'Inactive',
    user_text: 'user',
    user_info_text: 'User Info',
    connected_chat_text: 'Connected Chat',
    label_display_name: 'Display Name',
    label_username: 'Username',
    label_role: 'Role',
    label_email: 'Email',
    label_company: 'Company',
    label_worklocation: 'Work Location',
    label_is_active: 'Is Active',
    label_ms_teams: 'Microsoft Teams',
    label_whatsapp: 'Whatsapp Number',
    label_timezone: 'Timezone',
    placeholder_display_name: 'Enter name',
    placeholder_username: 'Enter username',
    placeholder_role: 'Select role',
    placeholder_email: 'Enter email',
    placeholder_company: 'Select company',
    placeholder_worklocation: 'Select work location',
    placeholder_ms_teams: 'Enter microsoft teams link',
    placeholder_whatsapp: 'example:088858888888',
    placeholder_timezone: 'Select timezone',
    success_edit_user: 'Success updated user data',
    required_msg: {
      company: 'Please input your company!',
      worklocation: 'Please input your worklocation!',
      display_name: 'Please input your display name!',
      username: 'Please input your username!',
      role: 'Please input your role!',
      email: 'Please input your email!',
      ms_teams: 'Please input your microsoft teams link!',
      whatsapp: 'Please input your whatsapp number!',
      timezone: 'Please input your timezone!'
    }
  },
  permit_request: {
    handle_tab_history: 'History & Conversation will be available after the submission',
    handle_tab_list_of_docs: 'Permit Name and Permit Type are required. Please complete the field to continue',
    handle_tab_list_of_docs_two: 'List of Required document profile still not exist, please contact PLIS administrator',
    handle_tab_product_doc: 'Some Product Doc fields are required. Please complete the field to continue',
    open_detail_permit: 'Open Detail Permit',
    activate_confirm_text: 'Are you sure already received the permit document',
    modal_edit_reference: {
      title: 'Edit Reference Permit',
      fill_form_validation_text: 'Please complete the form!',
    },
    modal_assign_to: {
      btn_cancel: 'Cancel',
      btn_assign: 'Assign',
      label_er_pic: 'ER PIC',
      placeholder_er_pic: 'Select ER PIC',
      label_comment: 'Comment',
      placeholder_comment: 'Add Comment',
    },
    modal_manual_reminder: {
      reminder: 'Reminder',
      automaticaly: 'Automaticaly',
      manually: 'Manually',
      date: 'Date',
      date_placeholder: 'Select date',
      time: 'Time',
      time_placeholder: 'Select time',
    },
    modal_reminder_feedback: {
      title: 'Do you want feedback previous permit, before create new permit?',
      rating_required_label: 'Please rate all questions!'
    },
    list_doc: {
      required_doc_label: 'Required Document',
      request_attach_label: 'Requester Attachment',
      request_attach_add_btn: 'Add Attachment',
      attach_label: 'Attachment',
      attach_placeholder: 'Select File',
      attach_two_placeholder: 'Drag & drop file here or ',
      remark_label: 'Remark',
      remark_placeholder: 'Enter Remark',
      remark_for_placeholder: 'Remark for ',
      attach_availability_label: 'Attachment Availability',
      available_label: 'Available',
      not_available_label: 'Not Available',
      browser_txt: 'browse',
      upload_failed_type_one: 'The file ',
      upload_failed_type_two: ' is not a valid file type',
      upload_failed_type_three: ' exceeds the maximum file size of ',
      template_label: 'Template',
      failed: 'Failed!',
      failed_upload: 'Failed upload',
      failed_delete_file: 'Failed delete file',
      uploading: 'Uploading...',
      not_found_text: 'Not Found'
    },
    request_information: {
      select_requestor_title: 'Select employee as requestor',
      general_information: 'General Information',
      name: 'Name',
      request_number: 'Request Number',
      location: 'Location',
      superior: 'Superior',
      requestor_detail: 'Requestor Detail',
      nik: 'NIK',
      division: 'Division',
      department: 'Department',
      position: 'Position',
      select_permit_request: 'Select Permit Request',
      request_type: 'Request Type',
      request_type_placeholder: 'Select request type',
      processed_by: 'Processed By',
      processed_by_placeholder: 'Select processed by',
      request_permit_information: 'Request Permit Information',
      activity: 'Activity',
      activity_placeholder: 'Enter activity',
      permit_name: 'Permit Name',
      permit_name_placeholder: 'Select permit name',
      permit_type: 'Permit Type',
      permit_type_placeholder: 'Select permit type',
      permit_tier: 'Permit Tier',
      permit_tier_placeholder: 'Select permit tier',
      issuer_institution: 'Issuer Institution',
      required_time: 'Required Time',
      required_time_placeholder: 'Select required time',
      permit_location: 'Permit Location',
      permit_location_placeholder: 'Select permit location',
      required_date: 'Required Date',
      required_date_placeholder: 'Select required date',
      description: 'Description',
      description_placeholder: 'Enter description',
      location_placeholder: 'Select location',
      er_pic_location: 'ER PIC (Location)',
      er_pic_location_placeholder: 'Select ER PIC (Location)',
      er_pic_name: 'ER PIC (Name)',
      er_pic_name_placeholder: 'Select ER PIC (Name)',
      guidance_url: 'Guidance URL',
      guidance_url_placeholder: 'Enter guidance url',
      guidance_image: 'Guidance Image',
      change_requestor_confirm: 'Employee\'s work location different, continue ?',
      success_message_save_draft: 'Successfully saved the permit request document',
      success_message_submit: 'Successfully created the permit request document',
      success_message_resubmit: 'Successfully resubmit the permit request document',
      reference_permit: 'Reference Permit',
      reference_permit_placeholder: 'Enter reference permit',
      reference_permit_select_placeholder: 'Select reference permit',
      expired_date: 'Expired Date',
      expired_date_placeholder: 'Select expired date',
      active_date: 'Active Date',
      active_date_placeholder: 'Select active date',
      hint_active_date: 'Date permit takes effect',
      release_date: 'Release Date',
      release_date_placeholder: 'Select release date',
      hint_release_date: 'Date the permit letter was issued',
      extend_required: 'Extend Required',
      extend_required_placeholder: 'Select extend required',
      work_location: 'Work Location',
      work_location_placeholder: 'Select work location',
      list_docs_confirm: 'There are some list of docs that still unsaved. Are you sure want to changes permit name?',
      success_message_revise: 'Successfully revise the permit request document',
      success_message_approve: 'Successfully approve the permit request document',
      success_message_assign_to: 'Successfully assign ER PIC to the permit request document',
      success_message_pick_up: 'Successfully pick up the permit request document',
      success_message_return: 'Successfully Return the permit request document',
      success_message_reject: 'Successfully Reject the permit request document',
      success_message_process: 'Successfully Process the permit request document',
      success_message_resubmission: 'Successfully Resubmission the permit request document',
      success_message_accept: 'Successfully Accept the permit request document',
      hint_select_processed_by: 'Select \'Processed By\' first',
      must_before: 'must be before the',
      empty_expired_date: 'Expired Date is empty'
    },
    history_conversation: {
      title: 'History & Conversation',
      history_title: 'History',
      conversation_title: 'Conversation',
      input_message_placeholder: 'Type your message',
      comment_title: 'Comment',
      report_title: 'Report',
      report: {
        indicator_title: 'Indicator',
        indicator_placeholder: 'Select indicator',
        expected_granted_title: 'Exp. Granted',
        expected_granted_placeholder: 'Select Expected Granted At',
        item_title: 'Item',
        item_placeholder: 'Entry item detail permit'
      }
    },
    product_doc: {
      document_information: 'Document Information',
      reg_number: 'Reg Number',
      accept_5_files: 'Only 5 files accepted',
      error_limit_file: 'The number of files exceeded the limit',
      attachment_url: 'Attachment via link',
      label_attachment_url: 'Paste an URL link here',
      or_label: 'OR'
    },
    invalid_active_or_release_date: 'Active Date or Release Date must be strictly before Expired Date',
    column: {
      date_modified: 'Date Modified',
      er_pic: 'ER PIC'
    }
  },
  permit_tracker: {
    period_date: 'Period Date',
    period_date_from_placeholder: 'Select Period Date from',
    period_date_to_placeholder: 'Select Period Date to',
    expired_date: 'Expired Date',
    expired_date_from_placeholder: 'Select expired date from',
    expired_date_to_placeholder: 'Select expired date to',
    date_error: 'Date to must be greater than date from!',
    to_txt: 'To',
  },
  permit_name: {
    processed: 'Processed',
    processed_est_time: 'Processed Est. Time',
    required_docs: 'Required Docs',
    permit_info: 'Permit Info',
    permit_name_placeholder: 'Enter permit name',
    priority_number: 'Priority Number',
    priority_number_placeholder: 'Enter priority number',
    process_estimation: 'Process Estimation',
    process_estimation_placeholder: 'Enter process estimation',
    institution: 'Institution',
    institution_placeholder: 'Type here to search Institution',
    reminder_management: 'Reminder Management',
    reminder_before: 'Reminder Before',
    reminder_before_placeholder: 'Enter reminder before',
    frequency_reminder: 'Frequency Reminder',
    frequency_reminder_placeholder: 'Enter frequency reminder',
    selected_role_for_reminder: 'Selected Role for Reminder',
    select_role_placeholder: 'Select role',
    list_of_required_document: 'List of Required Document',
    add_new_doc_btn: 'Add New Document',
    name_of_document: 'Name of Document',
    name_of_document_placeholder: 'Enter document name',
    upload_template: 'Upload Template',
    save_permit_name_btn: 'Save Permit Name',
    success_message_submit: 'Successfully created the permit name',
    success_message_update: 'Successfully update the permit name',
    permit_guidance: 'Permit Guidance',
    label_permit_name_id: 'Permit Name (ID)',
    label_permit_name_en: 'Permit Name (EN)',
    placeholder_permit_name_id: 'Enter permit name (ID)',
    placeholder_permit_name_en: 'Enter permit name (EN)',
    obligation: {
      add_obligation: 'Add Obligation',
      edit_obligation: 'Edit Obligation',
      column: {
        action: 'Action',
        obligation_name: 'Obligation Name'
      },
      delete_obligation: 'Delete Obligation',
      success_delete: 'Successfully deleted obligation',
      failed_delete: 'Failed to delete obligation',
      action_name: 'Action Name',
      last_update: 'Last Update',
      add_action: 'Add Action',
      action: 'Action',
      action_placeholder: 'Input action',
      obligation_name: 'Obligation Name',
      obligation_name_placeholder: 'Input obligation name',
      failed_sorting: 'Failed to move item',
      success_submit_add_obligation: 'Successfully added the obligation',
      failed_submit_add_obligation: 'Failed to add the obligation',
      success_submit_update_obligation: 'Successfully updated the obligation',
      failed_submit_update_obligation: 'Failed to update the obligation'
    },
    cant_move_to_tab_obligation: 'You do not have access to the obligation tab',
  },
  permit_monitoring: {
    days_left: 'Days Left',
    processed_by: 'Processed By',
    processed_by_placeholder: 'Select processed by',
    create_permit_monitoring: 'Create Permit Monitoring',
    edit_permit_monitoring: 'Edit Permit Monitoring',
    detail_permit_monitoring: 'Detail Permit Monitoring',
    permit_no: 'Permit No.',
    permit_no_placeholder: 'Enter permit number',
    permit_name: 'Permit Name',
    permit_name_placeholder: 'Select permit name',
    permit_location: 'Permit Location',
    permit_location_placeholder: 'Select permit location',
    area: 'Area (HA)',
    area_placeholder: 'Enter area',
    permit_type: 'Permit Type',
    permit_type_placeholder: 'Select permit type',
    institution: 'Institution',
    institution_placeholder: 'Select institution',
    description: 'Description',
    description_placeholder: 'Enter description',
    issued_date: 'Issued Date',
    issued_date_placeholder: 'Select issued date',
    release_date: 'Release Date',
    release_date_placeholder: 'Select release date',
    expired_date: 'Expired Date',
    expired_date_placeholder: 'Select expired date',
    status: 'Status',
    status_placeholder: 'Select status',
    success_message_submit: 'Successfully created the permit monitoring',
    success_message_update: 'Successfully update the permit monitoring',
    title_upload_docs: 'Product Doc (Only 5 files accepted)',
    remaining_days: 'Remaining Days',
    activity: 'Activity',
    activity_placeholder: 'Enter activity',
    permit_tier: 'Permit Tier',
    permit_tier_placeholder: 'Select permit tier',
    permit_info: 'Permit Info',
    progress_obligation: 'Progress Obligation',
    remaining_expired: 'Remaining Expired',
    compliance_status: 'Compliance Status',
    compliance_status_placeholder: 'Select compliance status',
    requestor: 'Requestor',
    requestor_placeholder: 'Enter requestor',
    sector: 'Sector',
    sector_placeholder: 'Enter sector',
    active_date: 'Active Date',
    active_date_placeholder: 'Select active date',
    permit_status: 'Permit Status',
    permit_status_placeholder: 'Select permit status',
    department: 'Department',
    department_placeholder: 'Select department',
    expire_reference: 'Expired Date Reference',
    expire_reference_placeholder: 'Select expired date reference',
    permit_reference: 'Permit Reference',
    permit_reference_placeholder: 'Enter permit reference',
    remarks: 'Remarks',
    remarks_placeholder: 'Enter remarks',
    attachment: 'Attachment',
    cant_move_to_tab_obligation: 'You do not have access to the obligation tab',
    error_validate_form: 'Some permit fields are required. Please complete the field to continue',
    success_message_update_permit: 'Successfully updated permit monitoring CRM',
    error_message_update_permit: 'Failed to update permit monitoring CRM',
    obligation: {
      obligation_action: 'Obligation Action',
      pic: 'PIC',
      pic_placeholder: 'Select pic',
      status: 'Status',
      status_placeholder: 'Select status',
      last_update: 'Last Update',
      due_date: 'Due Date',
      due_date_placeholder: 'Select due date',
      progress: 'Progress',
      success_edit_obligation: 'Obligation successfully edited',
      error_edit_obligation: 'Failed to edit obligation. Please try again',
      success_submit_add_action: 'Successfully added the action',
      failed_submit_add_action: 'Failed to add the action',
      complete_action: 'Complete the Action',
      success_complete_action: 'Action successfully completed',
      error_complete_action: 'Failed to complete action. Please try again',
      reason: 'Reason',
      reason_placeholder: 'Enter reason',
      give_a_note: 'Give a note to the user to fix the data',
      success_revise_action: 'Action successfully revised',
      error_revise_action: 'Failed to revise action. Please try again',
    }
  },
  permit_type: {
    delete_permit_type: 'Delete Permit Type',
    success_delete: 'Successfully deleted permit type',
    failed_delete: 'Failed to delete permit type',
    permit_type_name: 'Permit Type Name',
    permit_type_name_placeholder: 'Enter permit type name',
    upload_file_sub_label: 'Max file 10 MB, resolution 300 x 300',
  },
  compliance_monitoring: {
    start_date: 'Start Date',
    end_date: 'End Date',
    compliance_status: 'Compliance Status',
    compliance_status_placeholder: 'Select compliance status',
    curent_risk_assesment: 'Current Risk Assessment',
    curent_risk_assesment_placeholder: 'Select current risk assessment',
    status: 'Status',
    status_placeholder: 'Select status',
    compliance_permit: 'Compliance Permit',
    request_no: 'Request No',
    request_no_placeholder: 'Enter request no',
    permit_name: 'Permit Name',
    permit_name_placeholder: 'Enter permit name',
    description: 'Description',
    description_placeholder: 'Enter description',
    er_pic: 'ER PIC',
    er_pic_placeholder: 'Select ER PIC',
    permit_holder: 'Permit Holder',
    permit_holder_placeholder: 'Enter permit holder',
    permit_location: 'Permit Location',
    permit_location_placeholder: 'Enter permit location',
    sector: 'Sector',
    sector_placeholder: 'Enter sector',
    submitted_date: 'Submitted Date',
    submitted_date_placeholder: 'Select submitted date',
    likelihood: 'Likelihood',
    likelihood_placeholder: 'Select likelihood',
    impact: 'Impact',
    impact_placeholder: 'Select impact',
    original_target: 'Original Target',
    original_target_placeholder: 'Select original target',
    revised_target: 'Revised Target',
    revised_target_placeholder: 'Select revised target',
    impact_to_the_business: 'Impact To The Business',
    impact_to_the_business_placeholder: 'Enter impact to the business',
    remarks: 'Remarks',
    remarks_placeholder: 'Enter remarks',
    risk_assessment_status: 'Risk Assessment Status',
    document: 'Document',
    open_link: 'Open Link',
    action_plan: 'Action Plan',
    checklist: 'Checklist',
    progress: 'Progress',
    add_comment: 'Add Comment',
    add_item: 'Add Item',
    add_an_item: 'Add an item',
    success_message_create_permit: 'Successfully edited permit compliance monitoring',
    error_message_create_permit: 'Failed to edit permit compliance monitoring',
    fetch_action_plans_failure: 'Failed to fetch action plans. Please try again.',
    fetch_more_comment_failure: 'Failed to fetch more comment. Please try again.',
    success: {
      action_plan_created: 'Action Plan created successfully!',
      action_plan_deleted: 'Action Plan deleted successfully!',
      action_plan_updated: 'Action Plan updated successfully!',
      comment_added: 'Comment added successfully!',
      action_plan_checked: 'Action Plan checked successfully!',
    },
    error: {
      action_plan_create_failed: 'Failed to create Action Plan.',
      action_plan_delete_failed: 'Failed to delete Action Plan.',
      action_plan_update_failed: 'Failed to update Action Plan.',
      comment_add_failed: 'Failed to add comment.',
      action_plan_check_failed: 'Failed to check Action Plan.',
    },
  },
  manage_cronjob: {
    cron_name: 'Cron Name',
    integration: 'Integration',
    function_name: 'Function Name',
    time: 'Time',
    period: 'Period',
    last_run: 'Last Run',
    status: 'Status',
    action: 'Action',
    event: 'Event',
    health_status: 'Health Status',
    edit_cron_service: 'Edit Cron Service',
    detail_cron_service: 'Detail Cron Service',
    cron_expression: 'Cron Expression',
    active: 'Active',
    inactive: 'Inactive',
    plan_schedule: 'Plan Schedule',
    result_cron_expression: 'Result Cron Expression',
    success: {
      update_cronjob: 'Cronjob updated successfully',
    },
    error: {
      update_cronjob: 'Failed to update the cronjob',
      validation: 'Validation failed',
    },
  },
  general: {
    create_btn: 'Create',
    add_permit_name: 'Add Permit Name',
    add_permit_monitoring: 'Add Permit Monitoring',
    add_permit_type: 'Add Permit Type',
    edit_permit_type: 'Edit Permit Type',
    submit_btn: 'Submit',
    update_btn: 'Update',
    resubmit_btn: 'Re-Submit',
    cancel_btn: 'Cancel',
    save_draft_btn: 'Save Draft',
    submit_edit_btn: 'Save',
    activate_text: 'Activate',
    inactivate_text: 'In Activate',
    error_text: 'Error',
    success_text: 'Success',
    select_text: 'Select',
    week_text: 'Week',
    weeks_text: 'Weeks',
    day_text: 'Days',
    warning_text: 'Warning',
    required_field: ' is required!',
    loading_text: 'Loading...',
    send_back_btn: 'Send Back',
    comment_btn: 'Add comment',
    comment_label: 'Comment',
    approve_btn: 'Approve',
    assign_to_btn: 'Assign To',
    reassign_btn: 'Re-Assign',
    return_btn: 'Return',
    pick_up_btn: 'Pick Up',
    proceed_btn: 'Process',
    reject_btn: 'Reject',
    resubmission_btn: 'Resubmission',
    accept_btn: 'Accept',
    save_btn: 'Save',
    load_more: 'Load More',
    sort_by: 'Sort by',
    no_data: 'No Data Available',
    label_confirm_delete: 'Are you sure you want to delete this document',
    feedback_label: 'Feedback',
    feedback_rating_label: 'Rating & Feedback',
    label_confirm_feedback: 'Are you sure you want to cancel the feedback?',
    label_cancel_data: 'Will data changes be cancelled?',
    label_save_data: 'Will data changes be saved?',
    error_form_not_complete: 'Please complete the form!',
    back: 'Back',
    revise: 'Revise',
    upload_file: 'Upload File',
    complete_btn: 'Complete',
    send_request: 'Send Request',
    return_label: 'Return',
    send: 'Send',
    close: 'Close',
    see_more: 'See More',
    less: 'Less'
  },
  tab: {
    request_information: 'Request Information',
    list_docs: 'List of Required Docs',
    history_conversation: 'History & Conversation',
    product_doc: 'Product Doc.',
    general: 'General',
    obligation: 'Obligation',
    permit: 'Permit',
    conversation: 'Conversation',
    alignment: 'Alignment',
    permit_status: 'Permit Status',
    crm: 'CRM'
  },
  notification: {
    title: 'Notification',
    btn_show_all: 'Show All Notification',
    mark_as_read: 'Mark as read',
    no_token: 'No registration token available. Request permission to generate one.',
    confirm_text: 'Are you sure to mark the messages as read ?',
  },
  user_log: {
    last_online: 'Last Online: '
  },
  dashboard: {
    year: 'Year',
    year_placeholder: 'Select year',
    location: 'Location',
    location_placeholder: 'Select location',
    er_pic_loc: 'ER PIC LOC',
    er_pic_loc_placeholder: 'Select ER PIC Loc',
    permit_granted: 'Permit Granted',
    percent_permit_granted: '% Permit Granted',
    permit_by_location: 'Permit Request by Location',
    issuer_institution: 'Issuer Institution',
    permit_issuer_institution: 'Permit Request by Issuer Institution',
    issuer_institution_placeholder: 'Select issuer institution',
    by_month: 'Permit Monitoring',
    title_block_1: 'Total Permit Request All Company',
    title_block_2: 'Total Permit Request',
    title_chart_1: 'Compliance Status',
    title_chart_2: 'Compliance Activities',
    title_chart_3: 'Permit Request by Sector and Status',
    title_period: 'Period',
    permit_status: 'Permit Status',
    quartal: 'Quartal',
    permit_tier: {
      title: 'Permit Tier',
      high: 'High',
      medium: 'Medium',
      low: 'Low'
    },
    companies: {
      title: 'Companies',
      btn_detail: 'View Details',
      statistic_label: 'Statistic',
      percentage_label: 'Percentage Permit Granted',
      request_submit: 'Request Submit',
      request_guaranted: 'Request Guaranted',
      no_alignment: 'No Alignment to sharepoint',
      permit_status: {
        indicator_label: 'Indicator',
        indicator_placeholder: 'Select indicator',
        item_label: 'Item',
        exp_granted: 'Exp. Granted',
        last_update: 'Last Update'
      }
    }
  },
  application_profile: {
    title_form_edit: 'Edit Application Profile',
    title_form_view: 'Detail Application Profile',
    label_key: 'Key',
    label_value: 'Value',
    label_desc: 'Description',
    placeholder_key: 'Enter key',
    placeholder_value: 'Enter value',
    placeholder_desc: 'Enter description',
  },
  feedback: {
    add_category: 'Add Category',
    add_question: 'Add Question',
    category_placeholder: 'Type Category',
    question_placeholder: 'Type Question',
    category_label: 'Category',
    question_label: 'Questions',
    list_of_feedback_question: 'List of Feedback Questions',
    success_message_submit_edit_feedback: 'Successfully set up feedback',
    failed_message_submit_edit_feedback: 'Failed to set up feedback',
    valid_year: 'Valid Year',
    feedback_document: 'Feedback Document',
    feedback_application: 'Feedback Application'
  },
  report_feedback: {
    title: 'Feedback'
  },
  report_er_performance: {
    filter_date_reset_placeholder: 'Select Date Reset',
    reset_score: 'Reset Score',
    er_pic_name_column: 'ER PIC name',
    delay_score_column: 'Delay score',
    total_permits_processed_column: 'Total permits processed',
    total_late_permits_column: 'Total late permits',
    success_message_submit_reset_score: 'Successfully submit reset score',
    failed_message_submit_reset_score: 'Failed to submit reset score',
    confirm_description: 'Are you sure you want to reset? This action will permanently reset current progress and start the count from the beginning',
    reset_date: 'Reset Date',
    reset_date_placeholder: 'Select reset date',
    pic_work_location: 'PIC Work Location',
    pic_work_location_placeholder: 'Select pic work location',
    permit_name: 'Permit Name',
    request: 'Request',
    adjustment_delay_score: 'Adjustment Delay Score',
    adjustment_date: 'Adjustment Date',
    adjust_score: 'Adjust Score',
    score_before: 'Score Before',
    history_adjustment: 'History Adjustment',
    er_pic_name: 'ER PIC Name',
    permit_request_no: 'Permit Request No',
    adjust_delay_score: 'Adjust Delay Score',
    adjust_delay_score_placeholder: 'Enter adjust delay score',
    max: 'Max',
    reason: 'Reason',
    reason_placeholder: 'Enter reason',
    evidance: 'Evidance',
    evidance_placeholder: 'Enter evidance',
    browse: 'Browse',
    days: 'days',
    reach_max_score: 'Exceeds the maximum score',
    confirm_submit_content: {
      first: 'Are you sure you want to adjust the delay score from',
      second: 'to',
      third: '? The value cannot be rollback.',
    },
    success: {
      adjustment_delay_score: 'Adjustment delay score created successfully!',
    },
    error: {
      action_plan_create_failed: 'Failed to create adjustment delay score.',
    },
  },
  clusters: {
    btn_add: 'Add Cluster',
    column: {
      action: 'Action',
      cluster_name: 'Cluster Name',
      work_location: 'Work Location'
    },
    modal_form: {
      title_add: 'New Cluster',
      title_edit: 'Edit Cluster',
      label_cluster_name: 'Cluster Name',
      label_work_location: 'Work Location',
      placeholder_cluster_name: 'Enter cluster name',
      placeholder_work_location: 'Select work location',
      confirm_cancel_modal_add: 'Are you sure you will cancel adding the cluster?',
      confirm_cancel_modal_edit: 'Are you sure you will cancel editing the cluster?'
    }
  },
  permit_flow_process: {
    btn_view_guide: 'View Guide'
  },
  initiate_permit_request: {
    initiate_btn: 'Initiate Permit Request',
    initiate_title: 'Initiate Permit',
    permit_holder_label: 'Permit Holder',
    permit_holder_placeholder: 'Select permit holder',
    success_message_submit: 'Successfully created the initiate permit request',
    success_message_update: 'Successfully update the initiate permit request',
    date_label: 'Date',
    initiator_label: 'Initiator',
    modal_available_initiate: {
      title: 'There is one or more requests for you to make a permit'
    }
  },
  application_feedback : {
    title: 'Application Rating & Feedback',
    no_question: 'No questions yet'
  },
  email_notification: {
    datetime_sent: 'Datetime Sent',
    subject: 'Subject',
    email_body: 'Email Body',
    receiver_list_email: 'Receiver List Email',
    range_send_date: 'Range Send Date',
    start_date: 'Select start date',
    end_date: 'Select end date',
    to: 'To',
    cc: 'CC'
  }
};
