import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import History from './History';
import Conversation from './Conversation';
import Report from './Report';

const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
  <div>
    <DefaultTabBar
      {...props}
      className='px-10 rounded-xl text-[#AAAAAA] border-2 border-zinc-200'
      style={{ background: '#FFF' }}
    />
  </div>
);

const HistoryConversation = (props: any) => {
  const { id, activeTab, detail, detailReport, fetchReport, setActiveTab } = props;
  const { t } = useTranslation();
  const [triggerChange, setTriggerChange] = useState(false);
  const listStatusShowReport = ['open', 'processed', 'approved', 'close', 'returned', 'rejected'];

  const onChange = (key: string) => {
    setTriggerChange(!triggerChange);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('permit_request.history_conversation.conversation_title'),
      children: <Conversation id={id} triggerChange={triggerChange} detail={detail}/>,
    },
    {
      key: '2',
      label: t('permit_request.history_conversation.history_title'),
      children: <History id={id} triggerChange={triggerChange} />,
    },
    {
      key: '3',
      label: t('permit_request.history_conversation.report_title'),
      children: <Report 
        id={id} 
        triggerChange={triggerChange} 
        detail={detail} 
        detailReport={detailReport} 
        fetchReport={fetchReport}
      />,
    },
  ];

  const onTabClick = (e: any) => {
    setActiveTab(e);
  };

  return(
    <div className='bg-white lg:p-5'>
      <div className='bg-[#FDF0D8] p-3 rounded-lg flex flex-row items-center mb-4'>
        <span className='font-bold text-base'>{t('permit_request.history_conversation.title')}</span>
      </div>
      <Tabs 
        activeKey={activeTab ? activeTab : '1'}
        onTabClick={onTabClick}
        renderTabBar={renderTabBar} 
        items={!listStatusShowReport.includes(detail?.status?.toLowerCase()) ? items.filter((el:any) => el.key !== '3') : items} 
        onChange={onChange} 
      />
    </div>
  );
};

export default HistoryConversation;